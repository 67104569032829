import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  TabStrip,
  TabStripTab,
  Card,
  CardBody,
} from '@progress/kendo-react-layout';
import { Loader, ErrorFallback } from 'smart-react';
import { getOrganizationDetail } from '../../OrganizationDetail/Services/OrganizationService';
import OrganizationUser from '../../../Core/OrganizationUsers/Pages/OrganizationUser';
import { getDefaultTenant } from '../../../../Utils/Auth/ValidatePermissions';
import {
  ORGANIZATIONUSERS,
  DEFAULT_TENANT_NAME,
} from '../../../../constants/applicationConstants';
import { useAuth } from '../../../Core/Context/AuthContext';

/**
 * OrganizationUser main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The OrganizationUser component.
 */
const OrganizationUserPage = () => {
  const [selected, setSelected] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [orgId, setOrgId] = React.useState('');
  const { getTokensFromStorage, user, tenantID } = useAuth();
  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  React.useEffect(() => {
    const { accessToken } = getTokensFromStorage();
    (async () => {
      setLoading(true);
      const response = await getOrganizationDetail({
        accessToken,
        isServiceWorker: false,
        user,
      });
      let responseData = response?.data?.filter(
        (org) => org.name !== DEFAULT_TENANT_NAME,
      );
      if (responseData?.length > 0) {
        let tempData = responseData?.filter(
          (org) => org.tenant_id === tenantID,
        );
        if (tempData?.length > 0) {
          setOrgId(tempData?.[0]?.organization_id);
        } else {
          let tempData = responseData?.filter(
            (org) => org.tenant_id === getDefaultTenant()?.TenantId,
          );
          setOrgId(tempData?.[0]?.organization_id);
        }
      }
      setLoading(false);
    })();
  }, [tenantID]);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className='container mx-auto k-p-2'>
        {loading && <Loader />}
        <React.Suspense fallback={<Loader />}>
          <Card className='detail-grid-section k-pt-3'>
            <TabStrip
              className='page-tabs'
              selected={selected}
              onSelect={handleSelect}
            >
              <TabStripTab title='Users' contentClassName={`tabs-card`}>
                <Card className={`tabs-card detail-card`}>
                  <CardBody className='!k-px-0'>
                    <div className='card-body'>
                      <div className='container mx-auto k-p-2 add-page-section'>
                        <OrganizationUser
                          orgId={orgId}
                          moduleName={ORGANIZATIONUSERS}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </TabStripTab>
            </TabStrip>
          </Card>
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default OrganizationUserPage;
